@use '../abstracts/' as *;

/* home_topics
********************************************** */
.home_topics .switch {
  @include mq(sp) {
    margin-bottom: 132px;
  }
}
.home_topics--sankaku {
  &:nth-child(odd) {
    bottom: rem(-32);
    left: rem(-32);
    @include mq(sp) {
      bottom: sprem(-20.5);
      left: spvw(-20.5);
    }
  }
  &:nth-child(even) {
    top: rem(-32);
    right: rem(-32);
    transform: scale(-1,-1);
    @include mq(sp) {
      top: sprem(-20.5);
      right: spvw(-20.5);
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  &::before {
    bottom: 0;
    left: 0;
    @include rect(200);
    background-color: #E8EAEB;
    @include mq(sp) {
      @include sprect(100);
    }
  }
  &::after {
    bottom: rem(80);
    left: rem(80);
    @include rect(88);
    background-color: var(--clr-wht, #fff);
    @include mq(sp) {
      bottom: sprem(40);
      left: sprem(40);
      @include sprect(44);
    }
  }
}
.home_topics--list {
  @include mq(sp, min, ps) {
    display: flex;
    gap: 3px;
  }
}
.home_topics--list li {
  position: relative;
  padding: .5em;
  font-size: rem(18);
  z-index: 1;
  @include mq(sp, min, ps) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: rem(64);
    border-radius: rem(5) rem(5) 0 0;
    &:nth-last-child(n+6),
    &:nth-last-child(n+6) ~ li { /* 要素数が6個以上のときその全て */
      font-size: clamp(rem(14.5), perc(18,1340,vw), rem(18));
    }
    &:not(.all) {
      background-color: #E8EAEB;
    }
    &.all {
      color: var(--clr-wht, #fff);
      background-color: var(--clr-body, #333);
      &:not(.select)::before {
        box-shadow: 0 0 0 rem(4) rgba($color: #FDF4F4, $alpha: .5);
        background-color: #FDF4F4;
      }
    }
    &.select {
      color: var(--clr-body, #333);
      background-color: var(--clr-wht, #fff);
      box-shadow: 0 0 rem(15) rgba(78,52,38,.1);
      &::after {
        width: perc(168,214,'%');
      }
      span::before {
        opacity: 1;
      }
    }
    &::before,
    &::after {
      content: '';
    }
    &::before {
      display: block;
      width: perc(6,18,em);
      height: perc(6,18,em);
      box-shadow: 0 0 0 rem(4) rgba($color: $clr-body, $alpha: .3);
      background-color: var(--clr-body, #333);
      border-radius: 50%;
      margin-right: perc(10,18,em);
    }
    &[class*="bg-"]:not(.select) {
      color: var(--clr-wht, #fff);
    }
    &[class*="bg-"]::before {
      box-shadow: 0 0 0 rem(4) rgba($color: #FDF4F4, $alpha: .5);
      background-color: #FDF4F4;
    }
    &[class*="bg-"].select::before {
      box-shadow: 0 0 0 rem(4) rgba($color: $clr-body, $alpha: .3);
      background-color: var(--clr-body, #333);
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background-color: var(--clr-body, #333);
      transition: var(--transit-default, all .4s ease-out);
    }
    &:hover {
      opacity: .7;
    }
  }
  @include mq(sp) {
    background-color: var(--bg-wht, #fff) !important;
    &:not(.select) {
      background-color: var(--bg-wht, #fff) !important;
    }
  }
}
.home_topics--list span::before {
  @include mq(sp, min, ps) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + rem(15));
    background-color: var(--clr-wht, #fff);
    border-radius: rem(5) rem(5) 0 0;
    z-index: -1;
    opacity: 0;
    transition: var(--transit-default, all .4s ease-out);
  }
}
.home_topics--content {
  padding: 56px perc(100,1080,'%') 40px;
  box-shadow: 0 0 rem(15) rgba(78,52,38,.1);
}
.home_topics--deco {
  bottom: -109px;
  right: -176px;
  @include mq(1440) {
    right: -104px;
  }
  @include mq(sp) {
    right: -32px;
    width: 130px;
  }
}

/* home_topics-important
********************************************** */
.topics_list-important .icon {
  @include rect(24);
  margin-right: rem(12);
  @include mq(sp) {
    @include sprect(20);
    margin-right: 8px;
  }
}
.topics_list-important svg {
  fill: var(--clr-wht, #fff);
}
.home_topics-important .topics_list-important .post {
  background-color: #DA002A;
  padding-top: rem(10);
  padding-bottom: rem(10);
  @include mq(sp) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &:not(:last-child) {
    border-bottom: 2px solid var(--clr-wht, #fff);
  }
}
.topics_list-important .cat_list a {
  line-height: 1.75;
  background-color: var(--clr-wht, #fff);
}
.topics_list-important .cat_list * {
  color: #DA002A;
}
.topics_list-important .post--ttl {
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @include mq(sp) {
    width: 100%;
  }
}
.topics_list-important .post--link:not([href="javascript:void(0);"])::before {
  transition: var(--transit-default, all .4s ease-out);
}
.topics_list-important .post--link:not([href="javascript:void(0);"]):hover::before {
  background-color: rgba($color: #fff, $alpha: .3);
}