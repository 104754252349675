@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
  column-gap: perc(80,1200,'%');
  @include mq(sp) {
    height: auto !important;
  }
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
}
.sitemap--menu > li > a {
  font-size: rem(18);
  border-bottom: 2px dotted #ccc;
  padding-bottom: .5em;
  margin-bottom: 1.25em;
}
.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}
.sitemap_sub_nav a {
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: 1.25em;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: rem(8);
    height: rem(8);
    border-top: 2px solid var(--clr-main, #1D7FB7);
    border-right: 2px solid var(--clr-main, #1D7FB7);
    transform: rotate(45deg);
  }
}

@include mq(sp, min, ps) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.41%; /* 80px */
  }
}
@include mq(sp) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}