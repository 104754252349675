@use '../abstracts' as *;

/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}
.bg-off_wh {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.bg-bk {
  background-color: var(--bg-blk, #000);
}
.bg-cta {
  background-color: var(--btn-bg-cta, #333);
}
.bg-stripe {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: perc(1680,1903,'%');
  height: 100%;
  background-image: repeating-linear-gradient(90deg, var(--bg-off_wht, #f7f7f7), var(--bg-off_wht, #f7f7f7) 2px, transparent 2px, transparent perc(419,1680,'%'), var(--bg-off_wht, #f7f7f7) perc(419,1680,'%'));
  pointer-events: none;
  transition: var(--transit-default, all .4s ease-out);
  z-index: -1;
  opacity: .5;
  @include mq(sp) {
    background-image: repeating-linear-gradient(90deg, var(--bg-off_wht, #f7f7f7), var(--bg-off_wht, #f7f7f7) 2px, transparent 2px, transparent perc(183,369,'%'), var(--bg-off_wht, #f7f7f7) perc(183,369,'%'));
  }
}
.lps_sec:nth-child(2n) .bg-stripe {
  background-image: repeating-linear-gradient(90deg, var(--clr-wht, #fff), var(--clr-wht, #fff) 2px, transparent 2px, transparent perc(419,1680,'%'), var(--clr-wht, #fff) perc(419,1680,'%'));
  z-index: 0;
  @include mq(sp) {
    background-image: repeating-linear-gradient(90deg, var(--clr-wht, #fff), var(--clr-wht, #fff) 2px, transparent 2px, transparent perc(183,369,'%'), var(--clr-wht, #fff) perc(183,369,'%'));
  }
}