@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* footer_top
********************************************** */
  .footer_top.section_pdg {
    @include mq(sp, min, ps) {
      padding-bottom: 32px;
    }
  }
  .footer_top .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: perc(400,1200,'%') auto;
      column-gap: perc(128,1200,'%');
    }
  }
  .footer--logo {
    margin-bottom: rem(16);
  }
  .footer--logo .logo {
    width: 313px;
    fill: #fff;
  }
  .footer--ttl {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      flex: 1;
      background-color: var(--bg-wht, #fff);
    }
    &::before {
      margin-right: perc(12,15,em);
    }
    &::after {
      margin-left: perc(12,15,em);
    }
  }
  .footer--bnr:hover {
    opacity: .7;
  }
  .fnav--cta {
    @include mq(sp) {
      margin-bottom: rem(32);
    }
  }
  .fnav--cta a {
    max-width: 100% !important;
  }
  .fnav--contact a {
    font-size: rem(16);
    padding: perc(12,16,em) 1em;
  }
  .fnav--tel a {
    font-size: rem(16);
    padding: perc(11,16,em) 1em;
  }
  .fnav--tel .tel {
    font-size: rem(16) !important;
  }
  .fnav--tel .num {
    font-size: rem(24) !important;
  }

  /* ---fnav --- */
  .fnav--menu {
    @include mq(sp, min, ps) {
      font-size: rem(14);
    }
    @include mq(sp) {
      height: auto !important;
    }
  }
  .fnav--menu a {
    position: relative;
    display: block;
    padding: .25em 1em .25em 0;
    color: var(--clr-wht, #fff);
    &:hover {
      color: var(--clr-main, #1d7fb7);
    }
    @include mq(sp) {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        border-top: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
      }
    }
  }
  .fnav--menu .sub-menu a {
    padding-left: 1.25em;
    &::before {
      content: '';
      position: absolute;
      top: 1.25em;
      left: 0;
      width: .75em;
      height: 2px;
      border-bottom: 2px solid;
    }
  }

  /* ---sns_area --- */
  .sns_area {
    @include mq(sp, min, ps) {
      grid-column: 1/3;
    }
  }
  .sns--list li:not(:last-child) {
    margin-right: rem(24);
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(48);
    border-radius: 50%;
    background-color: var(--clr-wht, #fff);
    &:hover {
      background-color: var(--clr-main, #1d7fb7);
    }
  }
  .sns--list svg {
    fill: var(--clr-body, #333);
    width: auto;
    max-width: rem(30);
    max-height: rem(30);
  }

/* footer_btm
*************************************************** */
  .footer_btm {
    padding: rem(8) 0;
  }
  .footer_btm .inner > *:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: rem(16);
    }
    @include mq(sp) {
      margin-bottom: rem(8);
    }
  }
  .pbl a,
  .pbl img {
    display: block;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }

/* cookie
*************************************************** */
  #cookie-notice {
    font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
    line-height: var(--line-height-default, 1.8);
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: .08em;
    @include mq(sp, min, ps) {
      bottom: rem(16);
      left: inherit;
      right: rem(16);
      width: 100%;
      min-width: inherit;
      max-width: 420px;
      box-shadow: 0 0 rem(32) rgba($color: $clr-body, $alpha: .1);
    }
  }
  .cookie-notice-container {
    padding: rem(16) rem(32);
    text-align: left;
  }
  .cn-text-container {
    margin: 0;
  }
  .cn-buttons-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: rem(16);
  }
  .cn-buttons-container a {
    display: block;
    flex: 1;
  }
  .cn-buttons-container a:not(:last-child) {
    margin-right: rem(8);
  }
  .cn-buttons-container a:hover {
    opacity: .7;
  }
  #cookie-notice .cn-button:not(.cn-button-custom) {
    border-radius: 0;
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-size: rem(16);
    font-weight: 600;
    padding: rem(8) rem(16);
    margin-left: 0;
    color: #fff;
  }
  .cn-close-icon {
    top: 0;
    right: 0;
    margin-top: 0;
    width: rem(28);
    height: rem(28);
    padding: 0;
    background-color: var(--clr-body, #333);
    opacity: 1;
  }
  .cn-close-icon::before,
  .cn-close-icon::after {
    height: rem(24);
    width: rem(2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--clr-wht, #fff);
  }