@use '../abstracts' as *;

/* lps_parts--check
********************************************** */
.lps_parts--check .lps_parts--child {
  gap: rem(8);
  @include mq(sp, min, ps) {
    &.check-col5 {
      display: grid;
      grid-template-columns: repeat(5,1fr);
      .check_item {
        padding: perc(32,246,'%') perc(24,246,'%');
      }
    }
    &:not(.check-col5) {
      display: flex;
      .check_item {
        flex: 1;
        padding: perc(32,1280,'%') perc(24,1280,'%');
      }
    }
  }
  @include mq(sp) {
    display: grid;
    .check_item {
      padding: 24px;
    }
  }
}
.check_item .ttl-03 {
  font-size: rem(18);
  padding-left: rem(32);
  &::before {
    content: '';
    position: absolute;
    top: rem(3);
    left: 0;
    @include rect(24);
    background-image: url(../images/common/check-icn.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &::after {
    display: none;
  }
}
.check_item .mce-content-body {
  font-size: rem(15);
  color: #555;
}