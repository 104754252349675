@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: 14px;
  }
  &.noscroll {
    overflow: hidden;
  }
}
body {
  position: relative;
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, .08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #333);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  padding-top: 140px;
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: 64px;
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
    font-size: sprem(13);
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all .4s ease-out);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
/*! purgecss end ignore */
a:focus {
  text-decoration: none !important;
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}
svg {
  transition: var(--transit-default, all .4s ease-out);
}
svg[preserveAspectRatio="none"] {
  max-width: 100%;
  height: auto;
}
.g-recaptcha > div {
  margin-left: auto;
  margin-right: auto;
}

/* switch
********************************************** */
.switch-tab {
  cursor: pointer;
  transition: var(--transit-default, all .4s ease-out);
}
.switch-content:not(.random-content):not(:first-child) {
  display: none; 
}
.random-content {
  display: none;
}

@include mq(sp) {
  .switch-txt {
    padding: .5em 1em;
    border: 2px solid;
    border-radius: rem(8);
    background-color: var(--clr-wht, #fff);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 5px 0 5px;
      border-color: var(--clr-body, #333) transparent transparent transparent;
    }
  }
  .switch-wrap {
    position: fixed;
    top: 64px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 64px);
    padding: 56px perc(32,414,'%');
    background-color: rgba($color: #fff, $alpha: .8);
    z-index: z(middle);
    pointer-events: none;
    opacity: 0;
    transition: var(--transit-default, all .4s ease-out);
    &.active {
      pointer-events: auto;
      opacity: 1;
    }
  }
  .switch-close {
    @include rect(48);
    margin-top: -52px;
    margin-bottom: 4px;
    margin-left: auto;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 90%;
      height: 2px;
      border-bottom: 2px solid;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .switch-list {
    display: block;
    width: 100%;
    max-height: 476px;
    box-shadow: 0 0 sprem(16) rgba($color: $clr-body, $alpha: .1);
    border: 2px solid;
    border-radius: rem(8);
    overflow: auto;
  }
  .switch-list li:not(:last-child) {
    border-bottom: 2px dotted #ccc;
  }
}

/* anchor
********************************************** */
@include mq(sp) {
  .anchor-content:not(:last-child) {
    margin-bottom: sprem(28);
  }
}

/* blc
********************************************** */
.blc-lg:not(:last-child) {
  margin-bottom: rem(80);
}
.blc:not(:last-child) {
  margin-bottom: rem(64);
}
.blc-md:not(:last-child) {
  margin-bottom: rem(48);
}
.blc-sm:not(:last-child) {
  margin-bottom: rem(32);
}

/* deco_wrap
********************************************** */
.section-deco_wrap {
  position: relative;
  overflow: hidden;
}
.deco_wrap {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1903px;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  @include mq(sp) {
    width: 100%;
  }
}