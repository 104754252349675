@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a,
.btn .btn-area,
.mce-content-body .btn a[data-mce-selected] {
  position: relative;
  z-index: 1;
  display: block;
  padding: perc(10,18,em) perc(48,18,em);
  border-radius: 9999px;
  width: 100%;
  max-width: 360px;
  color: var(--btn-clr-main, #fff) !important;
  text-align: center;
  background-color: var(--btn-bg-main, #1D7FB7);
  font-weight: 600;
  font-size: rem(18);
  text-decoration: none !important;
  box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
  transition: var(--transit-default);
  @include mq(sp) {
    max-width: 100%;
    padding-left: perc(32,18,em);
    padding-right: perc(32,18,em);
  }
  &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
    content: '';
    position: absolute;
    top: 50%;
    right: perc(24,18,em);
    transform: translateY(-50%);
    width: perc(16,18,em);
    height: perc(16,18,em);
    background-color: var(--btn-clr-main, #fff);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    transition: var(--transit-default, all .4s ease-out);
    @include mq(sp) {
      right: perc(20,18,em);
    }
  }
  &[href^="#"]::before {
    clip-path: polygon(50% 100%, 0 0, 100% 0) !important;
  }
  &:hover {
    background-color: var(--btn-bg-main-hvr, #015691);
  }
}
.flex > .btn a {
  max-width: 100% !important;
}
.btn.txt-ctr a,
.btn.txt-ctr .btn-area {
  @include auto-margin;
}
.btn svg {
  fill: var(--btn-clr-main, #fff);
}
.btn-sm a {
  font-size: rem(16);
}
.btn-lg a {
  @include mq(sp, min, ps) {
    max-width: 400px;
    font-size: rem(20);
    padding: perc(16,20,em) perc(48,20,em);
  }
}
.btn-adj a {
  @include mq(sp, min, ps) {
    display: table;
    width: auto;
    max-width: inherit;
  }
}
.btn-next_page a {
  max-width: 100%;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333) !important;
  background-color: var(--btn-bg-wht, #fff);
  &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
    background-color: var(--btn-clr-wht, #333);
  }
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff) !important;
  background-color: var(--btn-bg-wht-hvr, #333);
  &::before {
    background-color: var(--btn-bg-wht, #fff) !important;
  }
  svg {
    fill: var(--btn-clr-wht-hvr, #fff);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  color: var(--btn-clr-cta, #fff);
  background-color: var(--btn-bg-cta, #333);
  font-weight: 600;
  padding-left: perc(40,18,em);
  padding-right: perc(40,18,em);
  @include mq(sp) {
    padding-left: perc(32,18,em);
    padding-right: perc(32,18,em);
  }
}
.box .btn-cta a {
  @include mq(sp) {
    padding-left: perc(24,18,em);
    padding-right: perc(24,18,em);
  }
}
.btn-cta:not(.btn-lg) a {
  @include mq(sp, min, ps) {
    max-width: 360px;
  }
}
.btn-cta svg {
  fill: var(--btn-clr-cta, #fff);
}
.btn-cta a:hover {
  color: var(--btn-clr-cta-hvr, #333);
  background-color: var(--btn-bg-cta-hvr, #000);
  svg {
    fill: var(--btn-clr-cta-hvr, #333);
  }
}
.btn-cta button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

/* --- btn-fx --- */
.btn-fx {
  @include mq(sp, min, ps) {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: z(top);
    transition: var(--transit-default, all .4s ease-out);
    pointer-events: none;
    opacity: 0;
  }
}
.header-sm .btn-fx {
  pointer-events: auto;
  opacity: 1;
}
.btn-fx a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
  font-size: rem(16);
  border-radius: 0 rem(10) rem(10) 0;
  padding: rem(20) 0 0;
  @include mq(sp, min, ps) {
    width: 80px;
    height: 280px;
  }
  @include mq(sp) {
    border-radius: sprem(10);
    padding: 1em perc(48,16,em);
  }
}
.btn-fx img {
  position: absolute;
  top: -33px;
  right: -56px;
  min-width: 108px;
  @include mq(sp) {
    top: -48px;
    right: -12px;
  }
}
.btn-fx span {
  display: block;
}
.btn-fx svg {
  width: rem(20);
  @include mq(sp, min, ps) {
    margin-bottom: rem(16);
  }
  @include mq(sp) {
    margin-right: rem(16);
  }
}
.btn-fx .arrow {
  position: relative;
  @include rect(20);
  background-color: var(--clr-wht, #fff);
  border-radius: 50%;
  @include mq(sp, min, ps) {
    margin-top: rem(20);
  }
  @include mq(sp) {
    margin-left: rem(20);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 10%;
    margin: auto;
    @include rect(7);
    color: var(--bg-main, #1D7FB7);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }
}

@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}