@use '../abstracts' as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: perc(373,414,'%');
  }
}
.inner-lg {
  @include mq(sp, min, ps) {
    width: perc(1680,1903,'%');
    max-width: perc(1680,1903,'%');
  }
}
.inner-md { max-width: 1280px; }
.inner-sm { max-width: 880px; }
.inner-sm2 { max-width: 1080px; }