@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: 2rem;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: 24px;
    background: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: 10px;
  }
  div#ez-toc-container p.ez-toc-title {
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: 1.0625rem;
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: 8px;
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a::before {
    content: "";
    position: absolute;
    top: .65rem;
    left: 0;
    width: .6rem;
    height: 0;
    border-bottom: 1px solid;
  }

/* pass
**************************************** */
  .post-password-form {
    p:not(:last-child) {
      margin-bottom: 1em;
    }
    input {
      transition: var(--transit-default, all .4s ease-out);
      &:hover {
        opacity: .7;
      }
    }
    input[type="password"] {
      border-radius: rem(5);
      padding: rem(3) rem(15);
      border: 1px solid #ccc;
      font: inherit;
      font-size: 1rem;
      &:focus {
        outline: none;
      }
    }
    input[type="submit"] {
      font-size: inherit;
      border: none;
      cursor: pointer;
      color: #fff;
      border-radius: rem(5);
      padding: rem(8) rem(15);
      -webkit-appearance: none;
      appearance: none;
      font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
      background-color: #ff6600;
    }
  }

/* share
**************************************** */
  .share--list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(6);
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .share--list a {
    position: relative;
    display: flex;
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1.4;
    font-size: rem(13);
    font-weight: bold;    
    border: 2px solid var(--clr-body, #333);
    background-color: var(--clr-wht, #fff);
  }
  .share--list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .share--list img {
    margin-right: rem(10);
  }
  .share--list .icon-new_tab {
    position: absolute;
    top: 50%;
    right: rem(16);
    transform: translateY(-50%);
    fill: #ccc;
    @include rect(18);
    opacity: 0;
  }
  .share--list a:hover .icon-new_tab {
    opacity: 1;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .archive .wp-pagenavi {
    font-family: var(--font-en, 'Montserrat', sans-serif);
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .25em 1em;
    background-color: var(--clr-body, #333);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wp-pagenavi a:hover {
    opacity: .7;
  }
  .wp-pagenavi a {
    color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include rect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: 8px;
    height: 8px;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -6px;
    @include mq(sp) {
      left: -5px;
    }
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -6px;
    @include mq(sp) {
      right: -5px;
    }
  }

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 32px;
  }
}

/* consultation_counter
**************************************** */
.consultation_counter--info .eyecatch img:not(:only-child) {
  width: 100%;
}
.consultation_counter--info .post--catch {
  border: 2px solid;
  background-color: rgba($color: #fff, $alpha: .8);
  padding: rem(20) rem(24);
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    font-size: sprem(17);
    padding: rem(16) rem(20);
  }
  &:not(:first-child) {
    margin-top: -1.5em;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - rem(48));
  }
}
.consultation_counter--info .table1 th {
  @include mq(sp, min, ps) {
    width: 11.6em;
  }
}
.consultation_counter--info .btn_wrap {
  margin-top: rem(40);
}

/* member-info
**************************************** */
.member-info--info .imgarea {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(sp, min, ps) {
    width: 30%;
    min-height: rem(300);
    margin-left: rem(40);
  }
  @include mq(sp) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
}
.member-info--info .txtarea {
  @include mq(sp, min, ps) {
    flex: 1;
  }
}
.info--name {
  display: block;
}
.info--ttl {
  position: relative;
  background-color: rgba($color: $clr-main, $alpha: .1);
  padding: .2em .5em .2em 1em;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: perc(5,24,em);
    height: 100%;
    background-color: var(--bg-main, #1D7FB7);
  }
}

/* interview
**************************************** */
.interview--info .eyecatch img {
  width: 100%;
}
.interview--info .eyecatch + .post--catch {
  margin-top: rem(-32);
}
.interview--info .post--catch .box {
  @include mq(sp, min, ps) {
    padding: 32px 40px;
  }
}
.interview--info .post--name {
  padding-bottom: .5em;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.interview--info .post--profile dt {
  width: rem(160);
  padding-right: rem(8);
  font-weight: 600;
}
.interview--info .post--profile dd {
  width: calc(100% - rem(160));
  padding-left: rem(8);
}

/* publications--info
**************************************** */
.publications--info .eyecatch > *:not(:last-child) {
  margin-bottom: rem(24);
}