/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes loop_slide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes loop_slide_sp {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}