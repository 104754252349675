@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  z-index: 10;
  width: perc(1680,1903,'%');
  margin-left: auto;
  margin-right: auto;
  background-image: url(../images/common/ptn-ttl.jpg);
  height: 240px;
  overflow-x: clip;
  @include mq(sp) {
    width: perc(373,414,'%');
    height: auto;
    min-height: 112px;
    padding-top: 24px;
    padding-bottom: 24px;
    overflow: hidden;
  }
}
.page_ttl--bg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(18);
  font-family: var(--font-en, 'Montserrat', sans-serif);
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.page_ttl-deco {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-transform: uppercase;
  font-size: rem(120);
  white-space: nowrap;
  z-index: -1;
  @include mq(sp) {
    font-size: rem(48);
  }
}
.page_ttl--sankaku {
  top: rem(-28.6);
  left: 0;
  width: 100%;
  z-index: 10;
  & + * {
    margin-top: rem(-57.2);
  }
  @include mq(sp) {
    &.sp-none-flex + * {
      margin-top: 0;
    }
  }
}
.page_ttl--sankaku .sankaku {
  display: block;
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
  &:nth-child(1) {
    fill: var(--clr-main, #23C4B7);
  }
  &:nth-child(2) {
    fill: #91E1DB;
  }
  &:nth-child(3) {
    fill: #BDEDE9;
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  z-index: 1;
}
.ttl-01,
.ttl-02,
.ttl-03:not(:last-child),
.ttl-04:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-01 {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(28);
  }
  &.txt-ctr .ttl-01-sub {
    margin-left: auto;
    margin-right: auto;
  }
}
.ttl-01-line {
  &:not(.txt-tate) {
    line-height: 1.4;
  }
  &::after {
    content: '';
    display: block;
    margin-top: .25em;
    width: 16px;
    height: 2px;
    background-color: var(--clr-main, #1d7fb7);
  }
  &.txt-ctr::after {
    margin-left: auto;
    margin-right: auto;
  }
  .ttl-01-sub {
    padding: 0;
  }
}
.ttl-01-line.txt-tate {
  @include mq(sp, min, ps) {
    display: flex;
    &::after {
      display: none;
    }
    .ttl-01-sub {
      -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
      text-indent: 1em;
      margin-bottom: 1.5em;
      &::after {
        content: '';
        display: block;
        margin-top: .5em;
        margin-left: 1em;
        width: 16px;
        height: 2px;
        background-color: var(--clr-main, #1d7fb7);
      }
    }
  }
}
.ttl-01-sub {
  position: relative;
  display: table;
  letter-spacing: var(--ltr-space-default, .08em);
  font-size: rem(16);
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 600;
  @include mq(sp) {
    font-size: sprem(12);
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    fill: #91E1DB;
    width: 9px;
    &:first-child {
      left: 0;
      transform: rotate(-90deg);
    }
    &:last-child {
      right: 0;
      transform: rotate(90deg);
    }
  }
}
.ttl-01-line .ttl-01-sub {
  font-size: rem(18);
}
.ttl-01-sankaku {
  width: 56px;
  height: 9px;
  .sankaku {
    &:nth-child(2),
    &:nth-child(3) {
      fill: var(--clr-main, #1d7fb7);
    }
    &:nth-child(2) {
      left: 16px;
      transform: rotate(-90deg);
    }
    &:nth-child(3) {
      right: 16px;
      transform: rotate(90deg);
    }
  }
}
.ttl-02 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: rem(28);
  @include mq(sp) {
    font-size: sprem(24);
  }
  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background-color: var(--clr-main, #1d7fb7);
    margin-top: .5em;
  }
  &.txt-ctr::after {
    margin-left: auto;
    margin-right: auto;
  }
}
.ttl-04 {
  font-size: rem(24);
}
.ttl-bd {
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 2px;
    background-color: var(--clr-body, #333);
  }
  &::before {
    margin-right: .8em;
    @include mq(sp) {
      margin-right: .6em;
    }
  }
  &::after {
    margin-left: .8em;
    @include mq(sp) {
      margin-left: .6em;
    }
  }
}