@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: .5em;
}
.mce-content-body ul li {
  list-style: none;
  &::before {
    content: '';
    position: absolute;
    top: 1.4em;
    left: -1em;
    @include rect(6);
    border: 1px solid var(--clr-main, #1D7FB7);
    background-color: var(--clr-main, #1D7FB7);
  }
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  max-width: 100%;
  height: auto;
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body .imgarea img {
  margin: 0;
}
.mce-content-body hr {
  border-top: 2px dotted #ccc;
}
.box {
  padding: 24px 32px;
  background-color: #E8EAEB;
}
.mce-content-body .box {
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}
.box-wh {
  background-color: var(--bg-wht, #fff);
  box-shadow: 0 0 rem(20) rgba(17,40,97,.1);
}
.box-red {
  background-color: #ffecec;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p:not(:last-child),
.wp-caption:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body-sm p:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body p:empty{
  display: none;
}
.mce-content-body .btn a[data-mce-selected] {
  margin: 0;
}
.wp-caption {
  max-width: 100%;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  padding: rem(16) rem(40);
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: .25;
}
.mce-content-body blockquote::before {
  background-image: url('../images/common/icon-quote_left.svg');
  top: rem(13);
  left: rem(10);
}
.mce-content-body blockquote::after {
  background-image: url('../images/common/icon-quote_right.svg');
  bottom: rem(13);
  right: rem(10);
}
.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) { margin-top: 1em; }
.mce-content-body table:not(:last-child) { margin-bottom: 1em; }
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 2px solid #D3D3D3;
  vertical-align: middle;
}
.mce-content-body th {
  min-width: 120px;
  text-align: left;
}
.mce-content-body sup {
  font-size: rem(12);
  color: #555;
}
.bold {
  font-weight: 600;
}
.mce-content-body .bold {
  background-image: linear-gradient(transparent 60%, #BDEDE9 60%);
}


/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table { margin: 0; }
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: .25;
  font-size: .875rem; /* 14px */
}

@include mq(sp) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft  {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
}
.mce-content-body.wp-editor * {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 2.5em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}
.editor-ttl {
  font-size: rem(32);
  padding-bottom: .25em;
  border-bottom: 2px dotted #ccc;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.editor-ttl2 {
  font-size: rem(24);
  color: var(--clr-sub, #333);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl3 {
  position: relative;
  font-size: rem(18);
  line-height: perc(31,18,em);
  z-index: 1;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &::before {
    content: '';
    position: absolute;
    top: -.8em;
    left: -.5em;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: perc(48,18,em) 0 0 perc(48,18,em);
    border-color: transparent transparent transparent #BDEDE9;
    z-index: -1; 
  }
}
.box:not(.box-wh) .editor-ttl3::before {
  border-color: transparent transparent transparent var(--clr-wht, #fff);
}
.editor-ttl3-sm {
  display: block;
  font-size: rem(16);
  &:not(:last-child) {
    margin-bottom: .5em;
  }
}

/* 注意書き */
.mce-content-body em,
.wp-caption-text,
.txt-attention {
  font-size: rem(14);
  color: #999;
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  &::before {
    content: '※';
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th {
  font-size: rem(18);
}
.table1 th::before,
.table1 td::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
  height: 2px;
}
.table1 th::before {
  left: 0;
  background-color: var(--clr-main, #1d7fb7);
}
.table1 td::before {
  right: 0;
  background-color: #D3D3D3;
}
.table2 th {
  background-color: #E8EAEB;
  border-right: 0;
  & + td {
    border-left: 0;
  }
}
.table2 td {
  background-color: var(--bg-wht, #fff);
}

/* --- btn-text --- */
.btn-text a {
  position: relative;
  display: inline-block;
  padding-left: rem(24);
  padding-bottom: rem(6);
  border-bottom: 2px dotted #CCC;
  text-decoration: none;
  color: var(--clr-main, #1D7FB7);
  font-weight: 700;
  font-size: rem(18);
  &::before {
    content: '';
    position: absolute;
    top: rem(10);
    left: 0;
    border: rem(8) solid transparent;
    border-left: rem(13) solid;
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th {
    border-bottom: 0;
    border-right: 2px solid #D3D3D3;
  }
  .table2.table-sp-block td {
    border-top: 0;
    border-left: 2px solid #D3D3D3;
  }
  .table2.table-sp-block tr:not(:last-child) td {
    border-bottom: 0;
  }
}