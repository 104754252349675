@use '../abstracts' as *;

/* contact
********************************************** */
.contact {
  padding-top: 78px;
  padding-bottom: 64px;
  .inner {
    position: relative;
    z-index: 1;
  }
  @include mq(sp) {
    padding-top: 44px;
    padding-bottom: 32px;
  }
}
.contact--sankaku .sankaku {
  &:nth-child(1) {
    fill: #FFDA82 !important;
  }
  &:nth-child(2) {
    fill: #F7B20E !important;
  }
  &:nth-child(3) {
    fill: var(--clr-sub, #FF8400) !important;
  }
}
.contact--ttl {
  z-index: 1;
  margin-bottom: .15em;
}
.contact--ttl .free {
  display: flex;
  justify-content: center;
  align-items: center;
  @include rect(88);
  border-radius: 50%;
  font-size: rem(23);
  color: var(--clr-wht, #fff);
  background-color: var(--clr-sub, #333);
  transform: rotate(-10deg);
  letter-spacing: .2em;
  text-indent: .2em;
  margin-right: rem(15);
  @include mq(sp) {
    @include sprect(64);
    font-size: sprem(17);
    margin-left: sprem(-64);
    margin-right: rem(11);
  }
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    margin: auto;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 2px dotted var(--clr-sub, #333);
    border-radius: 50%;
  }
}
.contact--ttl .font-en {
  top: -.75em;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: rem(96);
  color: #E8EAEB;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: .08em;
  z-index: -1;
  @include mq(sp) {
    font-size: sprem(48);
  }
}
.contact--cta {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp, min, ps) {
    & > *:not(.hours) {
      width: perc(400,840,'%');
    }
    & > *:not(:first-child):not(.hours) {
      margin-left: perc(40,840,'%');
    }
  }
}
.contact--tel {
  @include mq(sp) {
    order: 0;
  }
}
.contact--tel a {
  padding: perc(16.4,16,em) 1em;
}
.contact--tel .tel {
  font-size: rem(20) !important;
}
.contact--tel .num {
  font-size: rem(28) !important;
}
.contact--contact {
  @include mq(sp) {
    order: 2;
  }
}
.contact--contact a {
  font-size: rem(20);
  padding: perc(16,20,em) 1em;
}
.contact--contact svg {
  width: rem(28);
}
.contact--cta .hours {
  width: 100%;
  margin-top: rem(8);
  @include mq(sp) {
    order: 1;
    margin-bottom: 8px;
  }
}
.contact .deco_wrap {
  @include mq(sp) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 520/480;
      background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 80%, var(--bg-off_wht, #f7f7f7));
      z-index: 1;
      pointer-events: none;
    }
  }
}
.contact .deco_wrap img {
  top: 0;
}
.contact--bg01 {
  left: perc(-86,1903,'%');
  @include mq(sp) {
    left: 0;
  }
}
.contact--bg02 {
  right: perc(-83,1903,'%');
}

/* section-contact
********************************************** */
.section-contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.section-contact .cta_tel a {
  padding: 24px 40px;
  @include mq(sp) {
    .section-contact .cta_tel a {
      display: block;
      padding: 16px;
    }
  }
}