@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .inner:not(.inner-lg) { /* 通常サイズ */
  max-width: 1285px;
  .ttl-03 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
  &:not(.bg-wh) { /* 背景なし */
    @include mq(sp, min, ps) {
      align-items: flex-start;
    }
    .img_text--img {
      @include mq(sp, min, ps) {
        width: perc(880,1285,'%');
      }
      &::before {
        content: '';
        position: absolute;
        top: perc(214,587,'%');
        width: clamp(215px, perc(288,1444,vw), 288px);
        height: clamp(215px, perc(288,1444,vw), 288px);
        background-color: var(--clr-wht, #fff);
        z-index: 1;
        @include mq(sp) {
          top: spvw(128);
          left: 0;
          width: spvw(120);
          height: spvw(120);
          clip-path: polygon(0 0, 100% 0, 100% 100%) !important;
        }
      }
      &::after {
        top: perc(-40,587,'%');
        aspect-ratio: 880/587;
        @include mq(sp) {
          top: perc(-20,248,'%');
          left: inherit !important;
          right: perc(-20,373,'%') !important;
          aspect-ratio: 373/248;
        }
      }
    }
    .img-detail {
      @include mq(sp) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, perc(160,373,'%') 100%, 0 perc(88,248,'%')) !important;
      }
      &::before {
        padding-top: perc(587,880,'%');
      }
    }
    .img_text--txt {
      position: relative;
      z-index: 1;
      @include mq(sp, min, ps) {
        align-self: flex-start;
        margin-top: perc(263,1285,'%');
      }
    }
    .num {
      top: clamp(69px, perc(93,1444,vw), 93px);
      @include mq(sp) {
        top: spvw(182);
      }
    }
    &:not(.flex-reverse) { /* 奇数 */
      .img_text--img {
        &::before {
          right: 0;
          clip-path: polygon(100% 0, 0 0, 0 100%);
        }
        &::after {
          left: perc(-40,880,'%');
          clip-path: polygon(100% 0, 100% perc(107,587,'%'), perc(400,880,'%') 100%, 0 100%, 0 0);
          z-index: -1;
        }
      }
      .img-detail {
        clip-path: polygon(100% 0, 100% perc(107,587,'%'), perc(400,880,'%') 100%, 0 100%, 0 0);
      }
      .img_text--txt:not(:only-of-type) {
        @include mq(sp, min, ps) {
          margin-left: perc(-240,1285,'%');
        }
      }
      .num {
        left: inherit;
        right: 0;
        @include mq(sp) {
          left: 0;
          right: inherit;
        }
      }
    }
    &.flex-reverse { /* 偶数 */
      .img_text--img {
        &::before {
          left: 0;
          clip-path: polygon(100% 0, 0 0, 100% 100%);
        }
        &::after {
          right: perc(-40,880,'%');
          clip-path: polygon(0 0, 100% 0, 100% 100%, perc(480,880,'%') 100%, 0 perc(107,587,'%'));
          z-index: -1;
        }
      }
      .img-detail {
        clip-path: polygon(0 0, 100% 0, 100% 100%, perc(480,880,'%') 100%, 0 perc(107,587,'%'));
      }
      .img_text--txt:not(:only-of-type) {
        @include mq(sp, min, ps) {
          margin-right: perc(-240,1285,'%');
        }
      }
      .num {
        left: 0;
        right: inherit;
      }
    }
    &.only-img { /* 画像のみ */
      @include mq(sp, min, ps) {
        .num {
          top: clamp(217px, perc(290,1444,vw), 290px);
        }
        &:not(.flex-reverse) .num {
          right: perc(405,1285,'%');
        }
        &.flex-reverse .num {
          left: perc(405,1285,'%');
        }
      }
    }
    &.only-text { /* テキストエリアのみ */
      @include mq(sp) {
        .num {
          top: 0;
          left: inherit;
          right: 0;
          z-index: 0;
        }
      }
    }
  }
  &.bg-wh { /* 背景あり */
    .img_text--img {
      &:only-of-type { /* 画像のみ */
        width: 100%;
      }
      @include mq(sp, min, ps) {
        width: 50%;
      }
    }
  }
}
.lps_parts--img_text .inner-lg { /* Lサイズ */
  &[class*="only-"] {
    max-width: 1285px;
  }
  .ttl-03 {
    font-size: rem(40);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
  .img_text--img {
    &:only-of-type { /* 画像のみ */
      width: 100%;
    }
    @include mq(sp, min, ps) {
      width: perc(920,1680,'%');
    }
  }
  .img-detail::before {
    padding-top: perc(613,920,'%');
  }
  &:not(.bg-wh) { /* 背景なし */
    .img_text--img::after {
      top: perc(-40,613,'%');
      aspect-ratio: 920/613;
      @include mq(sp) {
        aspect-ratio: 373/248;
      }
    }
    &:not(.flex-reverse) { /* 奇数 */
      .img_text--img {
        &:not(:only-of-type) {
          @include mq(sp, min, ps) {
            margin-right: perc(120,1680,'%');
          }
        }
        &::after {
          left: perc(-120,920,'%');
          @include mq(sp) {
            left: perc(-20,373,'%');
          }
        }
      }
    }
    &.flex-reverse { /* 偶数 */
      .img_text--img {
        &:not(:only-of-type) {
          @include mq(sp, min, ps) {
            margin-left: perc(120,1680,'%');
          }
        }
        &::after {
          right: perc(-120,920,'%');
          @include mq(sp) {
            right: perc(-20,373,'%');
          }
        }
      }
    }
  }
}

/* 共通 */
.lps_parts--img_text {
  .only-text .num + .img_text--txt .text > *:first-child { /* テキストエリアのみ */
    padding-right: rem(188);
    @include mq(sp) {
      padding-right: rem(120);
    }
  }
  .bg-wh {
    box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
  }
  .inner:not(.bg-wh) .img_text--img {
    @include mq(sp) {
      margin-bottom: 24px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      background-image: url(../images/common/ptn-dot.png);
      z-index: -1;
    }
  }
  .bg-wh .img-detail {
    height: 100%;
  }
  .img-detail::before {
    @include mq(sp) {
      padding-top: perc(248,373,'%') !important;
    }
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      flex: 1;
      align-self: center;
    }
  }
  .bg-wh .img_text--txt {
    padding: 5%;
    @include mq(sp) {
      padding: perc(24,373,'%');
    }
  }
  .num {
    top: clamp(rem(18), perc(24,1444,vw), rem(24));
    font-size: clamp(rem(126), perc(168,1444,vw), rem(168));
    color: #E8EAEB;
    line-height: perc(139,168,em);
    font-weight: 600;
    text-shadow: 
    var(--clr-wht, #fff) 2px 0px 0px, var(--clr-wht, #fff) -2px 0px 0px,
    var(--clr-wht, #fff) 0px -2px 0px, var(--clr-wht, #fff) 0px 2px 0px,
    var(--clr-wht, #fff) 2px 2px 0px, var(--clr-wht, #fff) -2px 2px 0px,
    var(--clr-wht, #fff) 2px -2px 0px, var(--clr-wht, #fff) -2px -2px 0px,
    var(--clr-wht, #fff) 1px 2px 0px, var(--clr-wht, #fff) -1px 2px 0px,
    var(--clr-wht, #fff) 1px -2px 0px, var(--clr-wht, #fff) -1px -2px 0px,
    var(--clr-wht, #fff) 2px 1px 0px, var(--clr-wht, #fff) -2px 1px 0px,
    var(--clr-wht, #fff) 2px -1px 0px, var(--clr-wht, #fff) -2px -1px 0px,
    var(--clr-wht, #fff) 1px 1px 0px, var(--clr-wht, #fff) -1px 1px 0px,
    var(--clr-wht, #fff) 1px -1px 0px, var(--clr-wht, #fff) -1px -1px 0px;
    z-index: 2;
    @include mq(sp) {
      top: spvw(16);
      font-size: spvw(88);
    }
  }
  .inner:not(.flex-reverse) .num {
    left: clamp(rem(18), perc(24,1444,vw), rem(24));
    @include mq(sp) {
      left: spvw(16);
    }
  }
  .flex-reverse .num {
    right: clamp(rem(18), perc(24,1444,vw), rem(24));
    @include mq(sp) {
      right: spvw(16);
    }
  }
  .img_text--txt table { width: 100%; }
  .img_text--child:only-of-type .btn a {
    margin-left: auto;
    margin-right: auto;
  }
}