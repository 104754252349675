@use '../abstracts' as *;

/* home_btn
********************************************** */
.home_btn--list {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: perc(80,880,'%');
  }
}