@use '../abstracts' as *;

/* ##############################################################################

  FORMY

############################################################################## */

/* formy_form
********************************************** */
.lps_sec:nth-child(2n) #formy_form {
  background-color: var(--clr-wht, #fff);
  padding: rem(40);
  @include mq(sp) {
    padding: sprem(24);
  }
}
#formy_form table {
  width: 100%;
  a {
    text-decoration: underline;
  }
}
#formy_form th,
#formy_form td {
  padding: rem(16);
  vertical-align: top;
  border-bottom: solid 1px #eee;
  @include mq(sp) {
    display: block;
    width: auto;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  @include mq(sp, min, ps) {
    width: 40%;
  }
  @include mq(sp) {
    border-bottom:none;
    padding-bottom: sprem(8);
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table input[type="datetime-local"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  width: 100%;
  border-radius: rem(5);
  vertical-align: bottom;
  padding: rem(5) rem(15);
  border: 1px solid #ccc;
  font: inherit;
  font-size: 16px;
  transition: var(--transit-default, all .4s ease-out);
}
#formy_form table .flex {
  gap: 8px;
  & > *:not(.formy_error) {
    display: block;
    @include mq(sp, min, ps) {
      width: calc(50% - 4px);
      margin-bottom: 8px;
    }
  }
  &.flex-col3 > *:not(.formy_error) {
    @include mq(sp, min, ps) {
      width: calc(100% / 3 - 6px);
      margin-bottom: 8px;
    }
  }
}
#formy_form textarea { height: rem(100); }
#formy_form select { height: rem(44); }
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form .select_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#formy_form input:hover { opacity:.7; }
#formy_form textarea:hover { opacity:.7; }
#formy_form input:focus { outline: none; }
#formy_form .parsley-validated {  background-color: #eee; }
#formy_form .parsley-error {  background-color: #fee; }
#formy_form .parsley-success {  background-color: #fff; }
.help_text {
  font-size: rem(14);
  color: #999;
  line-height: 1.5;
  margin-top: .5em !important;
}
.help_text-important {
  color: red;
}
ul.help_text {
  list-style: disc !important;
}
ul.help_text li {
  margin-left: 2em;
}
.hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: rem(140);
  border: solid 1px #ccc;
  font-size: rem(14);
  padding: rem(8) rem(16);
}
.requiredIcon {
  background-color: #f55;
  color: #fff;
  margin-left: 1em;
  font-size: rem(12);
  padding: rem(2) rem(5);
  border-radius: rem(5);
  @include mq(sp) {
    font-size: sprem(10);
    margin-left: .5em;
  }
  &.sm {
    margin-left: 0;
    padding: 0;
    color: #f55;
    font-size: 1rem;
    background: none;
  }
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: rem(32);
  text-align: center;
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 9999px;
  padding: rem(16) rem(32);
  min-width: rem(300);
  margin-bottom: rem(16);
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp) {
    width: 100%;
  }
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"] { display: none !important; }
#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: .5em rem(8) .5em rem(40);
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}
#formy_form ul li label:hover { opacity: .7; }
#formy_form ul li label::before {
  content: '';
  position: absolute;
  width: rem(16);
  height: rem(16);
  border: 2px solid #788b93;
  left: rem(16);
  top: rem(12);
}
#formy_form ul li input[type="radio"] + label::before { border-radius: 10px; }
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before {
  border-color: #e75f5f;
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after {
  content: '';
  width: rem(10);
  height: rem(18);
  top: rem(4);
  left: rem(20);
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: #4dbaff; }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: rem(8) rem(27);
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 9999px;
}
.formy_error {
  display: block;
  color: red;
  margin-bottom: 1em;
  padding: .25em 1em;
  background-color: rgba($color: red, $alpha: .1);
  width: 100%;
  &:not(:first-child) {
    margin-top: .5em;
  }
}
.formy_send { background-color: #ff6600; }
#total_required {
  padding: rem(16);
  color: #f55555;
  text-align: center;
}

@include mq(sp, min, ps) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td { padding-top: 0; }
  .autoConfirmBack { margin-bottom: sprem(8); }
}

/* formy_form-sf
********************************************** */
.flatpickr-calendar {
  border-radius: 0 !important;
  box-shadow: 0 0 rem(20) rgba(17,40,97,.1) !important;
}
.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  display: none;
}
.flatpickr-time input {
  margin-top: -6px !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .numInputWrapper,
.flatpickr-day,
.flatpickr-time.time24hr .numInputWrapper,
.flatpickr-time input {
  transition: var(--transit-default, all .4s ease-out);
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: var(--clr-main, #1d7fb7) !important;
  border: none;
}
.flatpickr-weekday:nth-child(6) {
  color: rgba(0, 0, 255, .6);
}
.flatpickr-weekday:nth-child(7) {
  color: rgba(255, 0, 0, .6);
}
.flatpickr-day.saturday:not(.selected) {
  color: rgb(0, 0, 255);
}
.flatpickr-day.prevMonthDay.saturday:not(.selected),
.flatpickr-day.nextMonthDay.saturday:not(.selected),
.flatpickr-day.flatpickr-disabled.saturday:not(.selected) {
  color: rgba(0, 0, 255, .2);
}
.flatpickr-day.sunday:not(.selected),
.flatpickr-day.jp-holiday:not(.selected) {
  color: rgb(255, 0, 0);
}
.flatpickr-day.prevMonthDay.sunday:not(.selected),
.flatpickr-day.nextMonthDay.sunday:not(.selected),
.flatpickr-day.prevMonthDay.jp-holiday:not(.selected),
.flatpickr-day.nextMonthDay.jp-holiday:not(.selected),
.flatpickr-day.flatpickr-disabled.sunday:not(.selected),
.flatpickr-day.flatpickr-disabled.jp-holiday:not(.selected) {
  color: rgba(255, 0, 0, .2);
}
.flatpickr-hidden {
  display: none;
}
//flatpickrカーソル調整
input[id^="date"] + input {
  cursor: pointer;
}