@use '../abstracts/' as *;

/* home_bnr
********************************************** */
.posts-home_bnr {
  @include mq(sp, min, ps) {
    display: flex;
    flex-wrap: wrap;
  }
  .post {
    @include mq(sp, min, ps) {
      width: perc(402,1680,'%');
      margin-bottom: perc(24,1680,'%');
      margin-right: perc(24,1680,'%');
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: perc(-40,402,'%');
      right: perc(-22,402,'%');
      width: 100%;
      aspect-ratio: 402/200;
      background-image: url(../images/common/ptn-dot.png);
    }
  }
  .post--img {
    margin-bottom: rem(24);
    &::before {
      padding-top: perc(200,402,'%');
    }
  }
  .post--ttl {
    padding-right: rem(32);
    svg {
      position: absolute;
      top: .6em;
      right: rem(23);
      fill: var(--clr-main, #1D7FB7);
      transform: rotate(-90deg);
    }
  }
}