@use "../abstracts" as *;

/* step
********************************************** */
.step_label {
  display: flex;
  margin-bottom: rem(40) !important;
}
.step_label li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex: 1;
  gap: 0.1em;
  width: rem(80);
  padding: rem(8);
  padding-left: rem(16);
  color: var(--clr-main);
  font-size: rem(24);
  line-height: 1.3;
  border: 1px solid var(--bg-main);
  @include mq(sp) {
    font-size: 1rem;
  }
  &:not(:first-child) {
    margin-left: -1px;
    padding-left: sprem(10);
    padding-right: 0;
  }
  &:not(:last-child) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -20px;
      width: 20px;
      height: 100%;
      clip-path: polygon(0 100%, 0 0, 100% 50%);
      translate: 0 -50%;
      @include mq(sp) {
        right: -10px;
        width: 10px;
      }
    }
    &::before {
      background-color: var(--bg-main);
    }
    &::after {
      right: calc((20px - 1px) * -1);
      z-index: 1;
      background-color: var(--bg-wht);
      @include mq(sp) {
        right: calc((10px - 1px) * -1);
      }
    }
  }

  &.clear {
    color: var(--clr-wht);
    background-color: var(--bg-main);
    &::after {
      background-color: var(--bg-main);
    }
  }
  &.current {
    color: var(--clr-wht);
    background-color: var(--btn-bg-main-hvr);
    &::after {
      background-color: var(--btn-bg-main-hvr);
    }
  }
  span {
    font-size: rem(14);
  }
}
.step_area {
  @include mq(sp) {
    .ttl-03 {
      font-size: 1.5rem;
    }
  }
  &:not(:first-child) {
    display: none;
  }
}

// step_btn
.step_btn {
  cursor: pointer;
  &[data-step_direction="back"] {
    color: #aaa;
    &::before {
      left: perc(24, 18, em);
      scale: -1 1;
    }
  }
}
