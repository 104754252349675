@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 80px;
  padding-bottom: 80px;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section_pdg-sm {
  padding-top: 48px;
  padding-bottom: 48px;
  @include mq(sp) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.section_pdg-lg {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mq(sp) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section-circle {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: rem(-16);
    left: 50%;
    transform: translateX(-50%);
    @include rect(32);
    border-radius: 50%;
    background-image: radial-gradient(var(--clr-main, #1D7FB7) rem(4), rgba($color: $clr-main, $alpha: .3) rem(4), rgba($color: $clr-main, $alpha: .3));
  }
}