@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
// フォントサイズをREM:14引く1px
// これは古い .fz-{数字}のクラスをマネする
@include mq(sp) {
  @for $i from 8 to 140 {
    .fz-#{$i} { font-size: math.div(($i - 1), 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: rem(18); }
.txt-sm { font-size: rem(14); }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #1d7fb7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #333); }
.txt-default,
.txt-default * { color: var(--clr-body, #333); }

/* --- font --- */
.font-default {
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
}
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-en {
  font-family: var(--font-en, 'Montserrat', sans-serif);
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp, min, ps) {
  .txt-tate {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-font-feature-settings: 'pkna';
    font-feature-settings: 'pkna';
  }
  .txt-tate * {
    font-feature-settings: normal;
  }
}
@include mq(sp) {
  .txt-lg { font-size: sprem(15); }
  .txt-sm { font-size: sprem(13); }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}

/* clamp
**************************************** */
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.clamp-3 { -webkit-line-clamp: 3; }
.clamp-2 { -webkit-line-clamp: 2; }