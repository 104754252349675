@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--faq .faq_item:nth-child(odd) .ttl-03 {
  background-color: #E8EAEB;
}
.lps_parts--faq .faq_item:nth-child(even) .ttl-03 {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.lps_sec:nth-child(2n) .lps_parts--faq .ttl-03 {
  background-color: #fff;
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: rem(20) rem(40) rem(20) rem(84);
}
.lps_parts--faq .ttl-03 {
  margin-bottom: 0 !important;
  font-size: rem(20);
  &::after {
    display: none;
  }
}
.lps_parts--faq .mce-content-body {
  position: relative;
}
.lps_parts--faq .ttl-03::before,
.lps_parts--faq .mce-content-body::before {
  position: absolute;
  left: rem(31);
  display: block;
  font-weight: 600;
  font-size: rem(40);
}
.lps_parts--faq .ttl-03::before {
  content: 'Q';
  top: 0;
  color: var(--clr-main, #1d7fb7);
}
.lps_parts--faq .mce-content-body::before {
  content: 'A';
  top: rem(-8);
  color: #ccc;
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
      float: none !important;
      margin-top: 2px;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 8px !important;
      border-radius: 8px;
  }
}
