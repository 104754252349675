@use '../abstracts/' as *;

/* home_sns
********************************************** */
.home_sns.section_pdg {
  padding-bottom: 120px;
  @include mq(sp) {
    padding-bottom: 230px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: rem(-40);
    right: 0;
    @include rect(640);
    background-image: url(../images/common/ptn-dot.png);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    z-index: -1;
    @include mq(sp) {
      bottom: sprem(-20);
      @include sprect(320);
    }
  }
}
.home_sns--deco_wrap {
  height: calc(100% - 120px);
  @include mq(sp, min, ps) {
    @include mq(1660) {
      width: 1660px;
    }
    @include mq(1480) {
      width: 1900px;
      transform-origin: bottom;
      transform: translateX(-50%) scale(.8);
    }
    @include mq(1300) {
      display: none;
    }
  }
  @include mq(sp) {
    height: calc(100% - 16px);
  }
}
.home_sns--deco_wrap img {
  bottom: 0;
}
.home_sns--deco01 {
  left: perc(183,1903,'%');
  @include mq(sp) {
    width: 148px;
    left: 1.5%;
  }
}
.home_sns--deco02 {
  right: perc(121,1903,'%');
  @include mq(sp) {
    width: 180px;
    right: 1.5%;
  }
}
.home_sns--blc {
  z-index: 1;
  @include mq(sp, min, ps) {
    display: flex;
    justify-content: center;
    gap: perc(80,880,'%');
    & > * {
      width: calc(50% - perc(40,880,'%'));
    }
  }
  @include mq(sp) {
    & > *:not(:last-child) {
      margin-bottom: 8px !important;
    }
  }
  .twitter-timeline,
  a {
    display: block;
    border: 3px solid;
    border-radius: rem(14);
    box-shadow: 0 0 rem(30) rgba(17,40,97,.2);
  }
}
.twitter-timeline {
  color: #1D9BF0;
  &:nth-child(2) {
    display: none;
  }
}
.sns_btn {
  position: relative;
  z-index: 1;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 25px;
  }
  a {
    display: block;
    border: 3px solid;
    border-radius: rem(14);
    box-shadow: 0 0 rem(30) rgba(17,40,97,.2);
    background-color: var(--bg-wht, #fff);
    svg {
      fill: currentColor !important;
    }
  }
}
.sns_btn a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(24);
  padding: 1em perc(32,400,'%') 1em perc(48,400,'%');
  height: 117px;
  background-color: var(--clr-wht, #fff);
  @include mq(sp) {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &:hover {
    color: var(--clr-wht, #fff) !important;
  }
}
.sns_btn .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(32);
  height: rem(32);
}
.sns_btn .arrow {
  width: rem(16);
  transform: rotate(-90deg);
}