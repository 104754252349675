@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 140px;
  border-top: 5px solid var(--clr-main, #1d7fb7);
  z-index: z(header);
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med, min, ps) {
    @include mq(1740) {
      font-size: rem(14);
    }
  }
  @include mq(med) {
    height: 64px;
    background-color: var(--clr-wht, #fff);
    .bg-stripe {
      display: none;
    }
    & > .inner {
      padding-left: perc(21,414,'%');
    }
  }
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: perc(48,1903,'%');
    @include mq(med, min, ps) {
      padding-right: perc(40,1903,'%');
      @include mq(1740) {
        padding-left: perc(24,1903,'%');
        padding-right: perc(20,1903,'%');
      }
    }
  }
}
.page-contact .header {
  position: absolute;
}
.page-contact .header--logo a {
  pointer-events: none;
}
.page-contact .header--logo .logo {
  width: rem(240);
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}
.header--logo .logo {
  @include mq(med, min, ps) {
    width: 313px;
    @include mq(1740) {
      width: perc(313,1740,vw);
    }
  }
  @include mq(med) {
    width: auto;
    height: 40px;
  }
  .cls-1 {
    fill: #202347;
  }
  .cls-2 {
    fill: #2bb5a1;
  }
}

@include mq(med, min, ps) {
  .header-sm {
    height: 100px;
    background-color: var(--bg-wht, #fff);
    .bg-stripe {
      opacity: 0;
    }
    @include mq(1500) {
      .gnav {
        align-items: flex-start;
      }
    }
  }
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav,
.gnav_overlay {
  @include mq(med) {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.gnav {
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med, min, ps) {
    display: flex;
    align-items: center;
    @include mq(1200) {
      font-size: perc(16,1200,vw);
    }
  }
  @include mq(med) {
    width: perc(394,414,'%');
    margin: 0;
    padding: 24px perc(20.5,414,'%');
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    &.active {
      transform: translateX(0);
    }
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.gnav_overlay {
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med) {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--menu {
  @include mq(med, min, ps) {
    max-height: 69px;
    line-height: 1.8;
  }
  @include mq(med) {
    display: block;
    margin-bottom: 40px;
  }
}
.gnav--menu > li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 2px dotted #ccc;
  }
}
.gnav--menu a {
  @include mq(med) {
    display: block;
    padding: 12px 56px 12px 8px;
  }
  @include mq(sp) {
    padding: 13px 56px 13px 8px;
  }
}
.gnav--link {
  position: relative;
  padding: 0 vw(30);
  @include mq(med, min, ps) {
    flex-direction: column;
    align-items: flex-start !important;
    @include mq(1740) {
      padding: 0 vw(15);
    }
    @include mq(1200) {
      padding: 0 perc(16,1200,vw);
    }
  }
}
.gnav--link .font-en {
  display: block;
  text-transform: uppercase;
  @include mq(med, min, ps) {
    @include mq(1200) {
      font-size: perc(14,1200,vw);
    }
  }
  @include mq(med) {
    display: none;
  }
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  @include mq(med, min, ps) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: var(--transit-default, all .4s ease-out);
    opacity: 0;
    pointer-events: none;
  }
  @include mq(med) {
    position: relative;
    width: 100%;
    display: none;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
    padding-top: .5em;
    padding-bottom: .5em;
  }
}
.head_sub_nav li:not(:last-child) {
  @include mq(med, min, ps) {
    border-bottom: 1px solid #eee;
  }
}
.head_sub_nav a {
  display: block;
  padding: 16px 24px;
  @include mq(med, min, ps) {
    width: 15em;
    background-color: #fff;
    &:hover {
      opacity: .7;
    }
  }
  @include mq(med) {
    padding-top: .25em;
    padding-bottom: .25em;
    padding-left: 28px !important;
    &::before {
      content: '';
      position: absolute;
      top: 1.1em;
      left: 8px;
      width: 11px;
      height: 2px;
      background-color: var(--clr-main, #1d7fb7);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    transition: var(--transit-default, all .4s ease-out);
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 11px;
      height: 2px;
      border-bottom: 2px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}
.gnav--link .up {
  position: absolute;
  top: 50%;
  right: rem(-48);
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @include rect(40);
  line-height: 1;
  text-align: center;
  color: var(--clr-wht, #fff);
  background-color: var(--clr-sub, #333);
  border-radius: 50%;
  @include mq(1740) {
    transform: translate(rem(-30), rem(40));
    @include rect(36);
    font-size: rem(10);
  }
}
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 59px;
    z-index: 9999;
    transition: var(--transit-default, all .4s ease-out);
    &.active {
      background-color: #f7f7f7;
    }
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
  }
}
.tel_btn {
  @include mq(med) {
    right: 64px;
    fill: var(--clr-main, #1d7fb7);
  }
}
.tel_btn a {
  display: flex !important;
  svg {
    fill: var(--clr-main);
  }
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
  background-color: #E8EAEB;
  border-radius: 9999px;
  white-space: nowrap;
}
.cta_tel a > span {
  display: block;
  @include mq(sp, min, ps) {
    &.pc-none {
      display: none;
    }
  }
  @include mq(sp) {
    &.sp-none {
      display: none;
    }
  }
}
.cta_tel .tel {
  font-size: rem(24);
}
.cta_tel .num {
  font-size: rem(30);
}
.hours {
  color: #777;
  line-height: 1.8;
  margin-top: 1rem;
  & > span {
    display: block;
    &:not(:last-child) {
      @include mq(sp, min, ps) {
        margin-right: 1em;
      }
    }
  }
  .label {
    display: inline-block;
    padding: 0 .5em;
    border-radius: rem(3);
    margin-right: .5em;
    line-height: 1.6;
  }
}
.cta_contact a {
  display: flex;
  justify-content: center;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: .5em;
  }
  .free {
    padding: .2em .5em;
    border-radius: rem(3);
    color: var(--btn-bg-cta, #333);
    line-height: 1.2;
  }
}
.gnav--cta {
  @include mq(med, min, ps) {
    @include mq(1500) {
      position: absolute;
      top: calc(100% + 8px);
      right: perc(40,1903,'%');
      height: auto !important;
    }
  }
  @include mq(med) {
    flex-direction: column-reverse;
    br {
      display: none;
    }
  }
}
.gnav--cta li {
  @include mq(med) {
    &:not(:first-child) {
      margin-bottom: 8px;
    }
  }
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
  padding: 0 vw(30);
  background-color: transparent;
  @include mq(med, min, ps) {
    border-radius: 0;
    @include mq(1740) {
      padding: 0 vw(15);
    }
    @include mq(1500) {
      padding: .5em 1em;
      background-color: rgba($color: #fff, $alpha: .8);
      clip-path: polygon(10% 0%, 100% 0, 100% 70%, 90% 100%, 0 100%, 0% 30%);
    }
  }
  @include mq(med) {
    height: 56px;
    background-color: var(--clr-wht, #fff);
  }
}
.gnav--tel .tel {
  font-size: rem(16);
  @include mq(med, min, ps) {
    @include mq(1500) {
      font-size: rem(13);
    }
  }
}
.gnav--tel .num {
  font-size: rem(24);
  @include mq(med, min, ps) {
    @include mq(1500) {
      font-size: rem(20);
    }
  }
}
.gnav--tel .hours {
  @include mq(med, min, ps) {
    margin-top: 0;
    @include mq(1500) {
      .fz-14 {
        font-size: rem(11);
      }
      .fz-13 {
        font-size: rem(10);
      }
    }
  }
  & > span:not(:last-child) {
    margin-right: 0;
  }
}
.gnav--tel > .hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    .teltime {
      font-size: rem(17);
    }
    .label,
    .day {
      font-size: rem(15);
    }
  }
}
.gnav--tel a .hours {
  @include mq(med) {
    display: none;
  }
}
.gnav--contact {
  @include mq(med, min, ps) {
    transform: translateY(perc(32,140,'%'));
    .txt-sub {
      display: none;
    }
    @include mq(1500) {
      transform: none;
      margin-left: 1rem;
    }
  }
  @include mq(med) {
    .txt-sub {
      display: table;
      margin-bottom: .5em;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1.5em;
      padding-right: 1.5em;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: linear-gradient(to bottom, var(--clr-sub, #333), var(--clr-sub, #333) 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%);
        background-size: 4px 4px;
        width: 2px;
        height: 2em;
      }
      &::before {
        left: 0;
        transform: rotate(-20deg);
      }
      &::after {
        right: 0;
        transform: rotate(20deg);
      }
    }
  }
}
.gnav--contact a {
  font-size: rem(18);
  box-shadow: 0 0 1rem rgba($color: #333, $alpha: .2);
  @include mq(med, min, ps) {
    flex-direction: column;
    @include rect(160);
    border-radius: 50%;
    padding: 0;
    line-height: 1.4;
    @include mq(1500) {
      font-size: rem(15);
      @include rect(124);
      .free {
        font-size: rem(11);
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border: 1px solid;
      border-radius: 50%;
    }
    & > *:not(:last-child) {
      margin-bottom: .5em;
      margin-right: 0;
    }
  }
  @include mq(med) {
    height: 56px;
    margin-left: auto;
    margin-right: auto;
  }
  svg {
    width: perc(24,18,em);
  }
}
.gnav--contact svg {
  fill: #fff;
  margin-right: 8px;
}

@include mq(med, min, ps) {
  .gnav--menu > li > .gnav--link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: vw(30);
    width: 1em;
    height: 2px;
    background-color: var(--clr-main, #1d7fb7);
    @include mq(1200) {
      left: perc(16,1200,vw);
    }
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
  }
  .gnav--menu .menu-item-has-children > a > .font-jp::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: .2em;
    right: -1.25em;
    margin: auto;
    width: .5em;
    height: .5em;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    @include mq(med, min, ps) {
      @include mq(1740) {
        right: -1em;
      }
    }
  }
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}
@include mq(med) {
  .reserve_btn {
    margin-right: 64px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      padding: 0;
      aspect-ratio: 1;
      border-radius: 50%;
    }
    svg {
      margin-right: 0 !important;
      width: 20px;
      height: auto;
    }
  }
  .gnav--menu > li:not(.menu-item-has-children) a::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--clr-main, #1d7fb7);
      transition: var(--transit-default, all .4s ease-out);
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 7px;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
  }
  .active .gnav_btn--lines span {
    &:nth-of-type(1) {
      transform: translateY(7px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-7px) rotate(45deg);
    }
  }
}