@use '../abstracts' as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: rem(8);
  font-size: rem(13);
  @include mq(sp) {
    font-size: sprem(11);
  }
  .inner {
    z-index: 10;
  }
}
.breadcrumbs * {
  color: #aaa;
}
.breadcrumbs a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}