@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
}

/* --- post --- */
.posts + .btn {
  @include mq(sp) {
    margin-top: 24px;
  }
}
.post {
  position: relative;
}
.post--link.pos_ab {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.post--link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--img {
  background-color: var(--clr-off_wht, #f7f7f7);
}
.hover-lg .post--img {
  overflow: hidden;
}
.hover-lg .post--img > * {
  transition: all .8s ease-out;
}
.hover-lg:hover .post--img > *:not(.post--info) {
  transform: scale(1.05);
}
.hover-more .post--img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $clr-body, $alpha: .5);
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
}
.hover-more .more {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
  .icon-new_tab {
    fill: var(--clr-wht, #fff);
    margin-left: rem(8);
  }
}
.hover-more .post--ttl {
  transition: var(--transit-default, all .4s ease-out);
}
.hover-more .post--link:not([href="javascript:void(0);"]):hover + .post--img {
  &::after {
    opacity: 1;
  }
  .more {
    top: 50%;
    opacity: 1;
  }
  & + .post--txtarea .post--ttl {
    color: var(--clr-main, #1D7FB7);
  }
}
.hover-more .post--link:not([href="javascript:void(0);"]):hover + .post--txtarea .post--ttl {
  color: var(--clr-main, #1D7FB7);
}
.hover-flip .post--img .img {
	position: absolute;
	bottom: 0;
	left: 0;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
	transform-style: preserve-3d;
  transition: var(--transit-default, all .4s ease-out);
  pointer-events: none;
}
.hover-flip .img-front {
  transform: rotateY(0);
}
.hover-flip .img-back {
  transform: rotateY(-180deg);
  opacity: 0;
}
.hover-flip .post--img img {
  max-height: 100%;
}
.hover-flip .post--link:not([href="javascript:void(0);"]):hover + .post--img {
  .img-front {
    transform: rotateY(180deg);
    opacity: 0;
  }
  .img-back {
    transform: rotateY(0);
    opacity: 1;
  }
}
.logo.img-contain {
  max-width: 80%;
  .cls-1 {
    fill: #2bb5a1;
  }
  .cls-2 {
    fill: #202347;
  }
}
.post--date {
  color: #777;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.post--label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem(6);
}
.label-round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include rect(88);
  border-radius: 50%;
  font-size: rem(12);
  color: var(--clr-wht, #fff);
  background-color: rgba($color: $clr-main, $alpha: .8);
  line-height: 1.4;
  letter-spacing: .04em;
  svg {
    width: rem(24);
    fill: var(--clr-wht, #fff);
    margin-bottom: rem(4);
  }
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  &.no_link {
    pointer-events: none;
  }
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  background-color: #E8EAEB;
  padding: 0 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  text-align: center;
  border-radius: rem(16);
  &[class*="bg-"] {
    color: var(--clr-wht, #fff);
  }
  &[href="javascript:void(0);"] {
    pointer-events: none;
  }
  &:not([href="javascript:void(0);"]):hover {
    opacity: .7;
  }
}
.cat_list-wid a {
  padding: 0 .5em;
  width: rem(128);
  min-width: rem(128);
}
.area_list a {
  display: flex;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: .8);
  border: 2px solid;
  border-radius: 0;
  &:not([href="javascript:void(0);"]):hover {
    opacity: 1;
    color: var(--clr-wht, #fff);
    border-color: var(--clr-body, #333);
    background-color: var(--clr-body, #333);
    svg {
      fill: var(--clr-wht, #fff);
    }
  }
}
.area_list svg {
  width: rem(16);
  margin-right: rem(6);
  fill: var(--clr-main, #1D7FB7);
}
.post--ttl:not(:last-child),
.post--txt:not(:last-child) {
  margin-bottom: 1em;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 320px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:first-child) {
  padding-top: rem(40);
}
.side_section:not(:last-child):not(.box) {
  border-bottom: 2px dotted #ccc;
  padding-bottom: rem(40);
  padding-bottom: rem(40);
}
.side_section-profile p {
  line-height: var(--line-height-hdr, 1.4);
}
.profile--img {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  &::before {
    padding-top: 100%;
  }
  &:not(:last-child) {
    margin-bottom: rem(24);
  }
}
.side--ttl {
  font-size: rem(20);
  margin-bottom: 1em !important;
}
.side--ttl small {
  opacity: .3;
  margin-left: rem(8);
}

/* --- list --- */
.side--list a {
  display: block;
  text-decoration: none;
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
}
.side--list .children {
  margin-top: 0 !important;
}
.side--list .children li::before {
  background-color: var(--clr-wht, #fff);
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--img {
  margin-right: rem(16);
  width: rem(72);
  height: rem(72);
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: var(--line-height-default, 1.8);
  transition: var(--transit-default, all .4s ease-out);
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
  &::after {
    content: '';
    display: block;
    width: .5em;
    height: .5em;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
    transition: var(--trans-default, all .4s ease-out);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}
.archive_month a {
  position: relative;
  padding-left: 1.25em;
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: .5em;
    height: 2px;
    background-color: var(--clr-main, #1D7FB7);
  }
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 2;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 2px dotted #ccc;
  }
  &:hover {
    background-color: #E8EAEB;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: #fff;
  border: 2px solid #D3D3D3;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .08em;
  font-weight: 500;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: .4em;
  right: .8em;
  margin: auto;
  width: .6em;
  height: .6em;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: var(--transit-default, all .4s ease-out);
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 2px solid #D3D3D3;
  visibility: hidden;
  margin-top: -2px;
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
  z-index: 1;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
  @include mq(sp, min, ps) {
    display: flex;
    flex-direction: row-reverse;
    min-height: rem(268);
  }
  &:not(:last-child) {
    margin-bottom: rem(32);
  }
}
.posts-blog .post--img {
  @include mq(sp, min, ps) {
    width: perc(300,800,'%');
    min-height: 100%;
  }
  @include mq(sp) {
    &::before {
      padding-top: perc(3,4,'%');
    }
  }
}
.posts-blog .post--txtarea {
  padding: rem(30);
  @include mq(sp, min, ps) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-blog .post--ttl {
  @include mq(sp) {
    font-size: sprem(18);
  }
}

@include mq(sp, min, ps) {
  .blc-related {
    .posts-blog .post {
      min-height: auto;
    }
    .posts-blog .more {
      font-size: rem(13);
    }
    .posts-blog .post--ttl {
      font-size: rem(16);
    }
    .posts-blog .post--txtarea {
      padding: rem(16);
    }
    .posts-blog .post--info {
      display: none;
    }
    .posts-blog .post--txt {
      font-size: rem(13);
    }
  }
}

/* topics
**************************************** */

/* --- topics--archive --- */
.topics--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.topics--archive li {
  @include auto-margin(8);
}
.topics--archive a {
  color: #fff;
}

/* --- topics_list --- */
.topics_list .post {
  padding-bottom: rem(14);
  padding-top: rem(14);
  @include mq(sp) {
    display: block;
  }
  &:not(:last-child) {
    border-bottom: 2px dotted #ccc;
  }
}
.topics_list .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(15);
  }
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.topics_list .post--date {
  white-space: nowrap;
  @include mq(sp, min, ps) {
    width: perc(124,15,em);
  }
}
.topics_list .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}
.topics_list .post--ttl {
  flex: 1;
}
.topics_list:not(.topics_list-important) .post--link:not([href="javascript:void(0);"]):hover {
  opacity: .7;
}
.topics_list .icon-new_tab {
  margin-left: rem(8);
}

/* consultation_counter
**************************************** */
.posts-consultation_counter .post {
  box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
}
.posts-consultation_counter .post--img::before {
  padding-top: perc(3,4,'%');
}
.posts-consultation_counter .post--txtarea {
  padding: rem(20) rem(24);
  height: 100%;
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-consultation_counter .btn-cta {
  margin-top: auto;
}

/* member-info
**************************************** */
.member-info--ttl {
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.posts-member-info .post {
  @include mq(sp, min, ps) {
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }
  }
  @include mq(sp) {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
.posts-member-info .post--img {
  aspect-ratio: 3/4;
  background-color: transparent;
  margin-bottom: rem(24);
}
.posts-member-info .area_list {
  display: flex;
  justify-content: center;
}

/* interview
**************************************** */
.posts-interview .post {
  box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
}
.posts-interview .post--img::before {
  padding-top: perc(3,4,'%');
}
.posts-interview .post--txtarea {
  padding: rem(24) rem(32);
  height: 100%;
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-interview .post--ttl {
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.posts-interview .post--sankaku {
  padding-left: .5em;
  padding-right: .5em;
  @include mq(sp) {
    svg {
      width: sprem(14);
    }
  }
}
.posts-interview .post--sankaku .sankaku {
  transform: rotate(-90deg);
  &:nth-child(1) {
    fill: var(--clr-main, #23C4B7);
  }
  &:nth-child(2) {
    fill: #91E1DB;
  }
  &:nth-child(3) {
    fill: #BDEDE9;
  }
}

/* publications
**************************************** */
.posts-publications .post {
  box-shadow: 0 0 rem(20) rgba(17,40,97,.15);
  padding: rem(30);
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-publications .post--img {
  margin-bottom: rem(24);
  &::before {
    padding-top: perc(4,3,'%');
  }
}

@include mq(sp, min, ps) {
  .blc-related {
    .posts-publications .post {
      padding: rem(20);
    }
    .posts-publications .post--img {
      margin-bottom: rem(16);
    }
    .posts-publications .more {
      font-size: rem(14);
    }
    .posts-publications .post--ttl {
      font-size: rem(18);
    }
  }
}