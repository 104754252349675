@use '../abstracts' as *;

/* lps_parts--voice
********************************************** */
.lps_parts--voice .lps_parts--child {
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
  &:nth-child(odd) {
    .voice--txt::before {
      right: rem(-10);
    }
    .voice--fukidashi {
      left: rem(-26);
    }
    @include mq(sp, min, ps) {
      padding-left: perc(32,880,'%');
    }
  }
  &:nth-child(even) {
    direction: rtl;
    .voice--child {
      direction: initial;
    }
    .voice--txt::before {
      left: rem(-10);
    }
    .voice--fukidashi {
      right: rem(-26);
      transform: scaleX(-1);
    }
    @include mq(sp, min, ps) {
      padding-right: perc(32,880,'%');
    }
  }
  @include mq(sp, min, ps) {
    display: grid;
    align-items: center;
    grid-template-columns: perc(200,880,'%') auto;
  }
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.lps_parts--voice .voice--img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
}
.lps_parts--voice .img-detail {
  @include rect(100);
  border-radius: 50%;
  overflow: hidden;
  @include mq(sp) {
    @include rect(120);
  }
  &::before {
    padding-top: 100%;
  }
  &:not(:last-child) {
    margin-bottom: rem(18);
  }
}
.lps_parts--voice .voice--img_text {
  width: 100%;
  color: var(--clr-wht, #fff);
  background-image: repeating-linear-gradient(transparent, transparent perc(2,16,em), var(--clr-body, #333) perc(2,16,em), var(--clr-body, #333) perc(24,16,em), transparent perc(24,16,em), transparent perc(26,16,em));
  line-height: perc(26,16,em);
}
.lps_parts--voice .voice--txt {
  border: 2px solid #D3D3D3;
  background-color: var(--clr-wht, #fff);
  padding: rem(32) rem(40);
  font-size: rem(15);
  &::before {
    content: '';
    position: absolute;
    top: rem(10);
    width: 100%;
    height: 100%;
    background-color: #BDEDE9;
    z-index: -1;
  }
  @include mq(sp) {
    padding: 24px;
    &:not(:first-child) {
      margin-bottom: 24px;
    }
  }
}
.voice--fukidashi {
  bottom: 0;
  margin: auto;
  @include rect(26,28);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background-color: #D3D3D3;
  @include mq(sp, min, ps) {
    top: 0;
  }
  @include mq(sp) {
    left: 0;
    right: 0;
    bottom: -24px;
    clip-path: polygon(20% 0, 0% 100%, 100% 0);
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: rem(-3);
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    background-color: var(--clr-wht, #fff);
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    @include mq(sp) {
      top: calc(50% - 4px);
      right: -2px;
      clip-path: polygon(20% 0, 0% 100%, 100% 0);
    }
  }
}