@use '../abstracts/' as *;

/* sponsor
********************************************** */
.sponsor {
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: clamp(450px, vw(800), 800px);
    height: clamp(450px, vw(800), 800px);
    background-image: url(../images/common/ptn-dot.png);
    @include mq(sp) {
      width: vw(400);
      height: vw(400);
    }
  }
  &::before {
    bottom: -160px;
    left: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    @include mq(sp) {
      bottom: -80px;
    }
  }
  &::after {
    top: -160px;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    @include mq(sp) {
      top: -80px;
    }
  }
  .inner-lg {
    &::before,
    &::after {
      content: '';
      position: absolute;
      @include rect(240);
      background-color: #E8EAEB;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
      z-index: -1;
      @include mq(sp) {
        @include sprect(120);
      }
    }
    &::before {
      bottom: 0;
      left: 0;
    }
    &::after {
      top: 0;
      right: 0;
      transform: scale(-1,-1);
    }
  }
}
.sponsor--bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, calc(100% - 400px) 0%, 100% calc(100% - calc(100% - 400px)), 100% 100%, calc(100% - calc(100% - 400px)) 100%, 0% calc(100% - 400px));
  z-index: -2;
  @include mq(sp) {
    clip-path: polygon(0 0, calc(100% - 200px) 0%, 100% calc(100% - calc(100% - 200px)), 100% 100%, calc(100% - calc(100% - 200px)) 100%, 0% calc(100% - 200px));
  }
}
.sponsor--blc {
  width: perc(1552,1680,'%');
  max-width: perc(1552,1680,'%');
  margin-left: auto;
  margin-right: auto;
}
.area--list_wrap {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(32);
}
.area--list {
  @include mq(sp, min, ps) {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }
}
.area--list li {
  @include mq(sp, min, ps) {
    flex: 1;
    &:nth-last-child(n+5),
    &:nth-last-child(n+5) ~ li { /* 要素数が5個以上のときその全て */
      flex: inherit;
      width: calc(20% - 6px);
    }
  }
}
.area--list:not(.anchor-list) li,
.area--list.anchor-list a {
  padding: .5em;
  font-size: rem(18);
  background-color: var(--clr-wht, #fff);
  @include mq(sp, min, ps) {
    border: 2px solid;
    border-radius: rem(8);
  }
  &.select,
  &:hover {
    color: var(--clr-wht, #fff);
    background-color: var(--clr-main, #1d7fb7);
    @include mq(sp, min, ps) {
      border-color: var(--clr-body, #333);
    }
  }
}
.area--list.anchor-list a {
  display: block;
}
.sponsor--bnr {
  gap: 8px;
}
.sponsor--bnr li {
  width: calc(20% - 6.4px);
  background-color: var(--clr-wht, #fff);
  @include mq(sp, min, ps) {
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }
  }
  @include mq(sp) {
    width: calc(50% - 4px);
  }
  &::before {
    padding-top: perc(95,304,'%');
  }
}
.sponsor--bnr a {
  display: block;
  &:hover {
    opacity: .7;
  }
}