@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 880px;
  @include auto-margin;
  padding-left: rem(86);
  counter-increment: number;
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: rem(40);
}
.lps_parts--flow .ttl-03 {
  color: var(--clr-main, #1d7fb7);
  &::before {
    content: counter(number);
    position: absolute;
    top: rem(-8);
    left: rem(-80);
    display: flex;
    @include center-flex;
    @include rect(64);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: var(--clr-main, #1D7FB7);
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-weight: 600;
    font-size: rem(26);
    letter-spacing: 0;
  }
  &::after {
    display: none;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: rem(64);
  left: rem(36);
  display: block;
  width: 0;
  height: calc(100% - rem(80));
  border-right: 1px solid var(--clr-main, #1d7fb7);
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::before {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}
.lps_parts--flow .text img {
  width: 100%;
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}
.lps_parts--flow .text table img {
  margin-right: 0;
}
