@use '../abstracts' as *;

/* home_feature
********************************************** */
.home_feature {
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
  }
  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-off_wht, #f7f7f7);
    clip-path: polygon(calc(100% - calc(100% - 400px)) 0%, 100% 0, 100% calc(100% - 400px), calc(100% - 400px) 100%, 0 100%, 0% calc(100% - calc(100% - 400px)));
    @include mq(sp) {
      clip-path: polygon(calc(100% - calc(100% - 200px)) 0%, 100% 0, 100% calc(100% - 200px), calc(100% - 200px) 100%, 0 100%, 0% calc(100% - calc(100% - 200px)));
    }
  }
  &::after {
    bottom: 0;
    right: 0;
    width: clamp(450px, vw(800), 800px);
    height: clamp(450px, vw(800), 800px);
    background-image: url(../images/common/ptn-dot.png);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    @include mq(sp) {
      width: vw(400);
      height: vw(400);
    }
  }
}
.home_feature--bnr:hover {
  opacity: .7;
}
.home_feature--wrap {
  @include mq(sp, min, ps) {
    height: max(527px, vw(929));
  }
}
.home_feature--blcs {
  @include mq(sp, min, ps) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: max(527px, vw(929));
  }
}
.home_feature--blcs-ttl {
  @include mq(sp) {
    margin-bottom: 56px;
  }
}
.home_feature--blcs-control {
  z-index: 1;
  pointer-events: none;
}
.home_feature--arrows {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}
.home_feature--arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: clamp(36px, vw(64), 64px);
  height: clamp(36px, vw(64), 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  &::before {
    content: '';
    display: block;
    width: clamp(23px, vw(40), 40px);
    height: clamp(23px, vw(40), 40px);
    transform: rotate(45deg);
    color: var(--clr-main, #1d7fb7);
  }
  &.prev {
    left: clamp(-23px, vw(-40), -40px);
    @include mq(1670) {
      left: 0;
    }
    &::before {
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  &.next {
    right: clamp(-23px, vw(-40), -40px);
    &::before {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }
}
.home_feature .slick-arrow {
  &::after {
    width: clamp(23px, vw(40), 40px);
    height: clamp(23px, vw(40), 40px);
  }
  &.slick-prev {
    left: clamp(-23px, vw(-40), -40px);
    @include mq(1670) {
      left: 0;
    }
    &::after {
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  &.slick-next {
    right: clamp(-23px, vw(-40), -40px);
    &::after {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }
}
.home_feature--ttl {
  @include mq(sp, min, ps) {
    position: absolute !important;
    top: clamp(58.5px, vw(104), 104px);
    right: perc(60,1680,'%');
    font-size: clamp(rem(27), vw(48), rem(48)) !important;
  }
  @include mq(sp) {
    font-size: sprem(24) !important;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: clamp(5.6px, vw(10), 10px);
    left: clamp(-22.5px, vw(-40), -40px);
    width: clamp(112.5px, vw(200), 200px);
    height: clamp(112.5px, vw(200), 200px);
    background-color: #E8EAEB;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    z-index: -1;
  }
  .ttl-01-sub {
    font-size: clamp(rem(11.25), vw(20), rem(20)) !important;
    &::after {
      width: clamp(9px, vw(16), 16px) !important;
    }
  }
}
.home_feature--scrolls {
  bottom: clamp(36px, vw(64), 64px);
  left: perc(80,1680,'%');
  pointer-events: auto;
  @include mq(sp) {
    bottom: spvw(72);
  }
}
.home_feature--scroll {
  position: relative;
  display: block;
  font-size: clamp(rem(13.5), vw(24), rem(24));
  color: #ccc;
  line-height: 1.5;
  transition: var(--transit-default, all .4s ease-out);
  cursor: pointer;
  &:not(:last-child) {
    margin-right: clamp(22.5px, vw(40), 40px);
  }
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: var(--clr-main, #1D7FB7);
    transition: var(--transit-default, all .4s ease-out);
  }
  &.current {
    color: var(--clr-main, #1D7FB7);
    &::before {
      width: 100%;
    }
  }
}
.home_feature .slick-dots {
  position: absolute;
  bottom: clamp(36px, vw(64), 64px);
  left: perc(80,1680,'%');
  width: fit-content;
  pointer-events: auto;
  @include mq(sp) {
    bottom: spvw(72);
  }
}
.home_feature .slick-dots li {
  background-color: transparent;
  width: auto;
  height: auto;
  &:first-child,
  &:last-child {
    margin: 0;
  }
  &:not(:last-child) {
    margin-right: clamp(22.5px, vw(40), 40px);
  }
  &.slick-active {
    background-color: transparent;
    button {
      color: var(--clr-main, #1D7FB7);
      &::before {
        width: 100%;
      }
    }
  }
  &:hover {
    opacity: 1;
    button {
      color: var(--clr-main, #1D7FB7);
    }
  }
}
.home_feature .slick-dots button {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-family: var(--font-en, 'Montserrat', sans-serif);
  font-size: clamp(rem(13.5), vw(24), rem(24));
  font-weight: 500;
  color: #ccc;
  line-height: 1.5;
  transition: var(--transit-default, all .4s ease-out);
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: var(--clr-main, #1D7FB7);
    transition: var(--transit-default, all .4s ease-out);
  }
  &:focus {
    outline: none;
  }
}
.home_feature--sankaku {
  top: clamp(58.5px, vw(104), 104px);
  right: min(-6px, vw(-12));
  width: clamp(10px, vw(24), 24px);
  height: clamp(10px, vw(87), 87px);
  @include mq(sp) {
    top: 0;
    right: 0;
  }
}
.home_feature--sankaku .sankaku {
  display: block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: clamp(6px, vw(12), 12px);
  }
  &:nth-child(1) {
    fill: var(--clr-main, #23C4B7);
  }
  &:nth-child(2) {
    fill: #91E1DB;
  }
  &:nth-child(3) {
    fill: #BDEDE9;
  }
}
.home_feature--blc {
  display: flex !important;
  @include mq(sp, min, ps) {
    position: absolute;
    top: 0;
    left: 0;
    transition: var(--transit-default, all .4s ease-out);
    width: 100%;
    padding-top: clamp(92px, vw(164), 164px);
    padding-bottom: clamp(36px, vw(64), 64px);
    padding-left: vw(80);
    &.current {
      opacity: 1;
      pointer-events: auto;
    }
  }
  @include mq(sp) {
    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }
  .imgarea {
    width: perc(1200,1600,'%');
    @include mq(sp) {
      width: perc(414,373,'%');
      margin-bottom: 24px;
      margin-left: perc(-20.5,373,'%');
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
    &::before {
      top: perc(316,640,'%');
      right: perc(164,1200,'%');
      width: max(112.5px, vw(200));
      height: max(112.5px, vw(200));
      background-color: var(--clr-wht, #fff);
      clip-path: polygon(100% 0, 0 0, 0 100%);
      z-index: 1;
      @include mq(sp) {
        right: perc(8,414,'%');
        width: spvw(100);
        height: spvw(100);
      }
    }
    &::after {
      top: perc(-40,640,'%');
      left: perc(-40,1200,'%');
      aspect-ratio: 1200/640;
      width: 100%;
      background-image: url(../images/common/ptn-dot.png);
      clip-path: polygon(100% 0, 100% perc(116,640,'%'), perc(640,1200,'%') 100%, 0 100%, 0 0);
      z-index: -1;
      @include mq(sp) {
        
      }
    }
    .point {
      position: absolute;
      top: 0;
      left: 0;
      text-shadow: 
      var(--clr-wht, #fff) 2px 0px 0px, var(--clr-wht, #fff) -2px 0px 0px,
      var(--clr-wht, #fff) 0px -2px 0px, var(--clr-wht, #fff) 0px 2px 0px,
      var(--clr-wht, #fff) 2px 2px 0px, var(--clr-wht, #fff) -2px 2px 0px,
      var(--clr-wht, #fff) 2px -2px 0px, var(--clr-wht, #fff) -2px -2px 0px,
      var(--clr-wht, #fff) 1px 2px 0px, var(--clr-wht, #fff) -1px 2px 0px,
      var(--clr-wht, #fff) 1px -2px 0px, var(--clr-wht, #fff) -1px -2px 0px,
      var(--clr-wht, #fff) 2px 1px 0px, var(--clr-wht, #fff) -2px 1px 0px,
      var(--clr-wht, #fff) 2px -1px 0px, var(--clr-wht, #fff) -2px -1px 0px,
      var(--clr-wht, #fff) 1px 1px 0px, var(--clr-wht, #fff) -1px 1px 0px,
      var(--clr-wht, #fff) 1px -1px 0px, var(--clr-wht, #fff) -1px -1px 0px;
      font-size: clamp(rem(45), vw(80), rem(80));
      font-weight: 600;
      line-height: 1;
      letter-spacing: .08em;
      margin-top: -.9em;
      margin-left: -1.1em;
      z-index: 1;
      @include mq(sp) {
        margin-left: 0;
        left: perc(20.5,414,'%');
      }
    }
    .num {
      font-size: clamp(rem(67.5), vw(120), rem(120));
    }
  }
  .img-detail {
    clip-path: polygon(100% 0, 100% perc(116,640,'%'), perc(640,1200,'%') 100%, 0 100%, 0 0);
    @include mq(sp) {
      clip-path: polygon(100% 0, 100% perc(80,220,'%'), perc(274,414,'%') 100%, 0 100%, 0 0);
    }
    &::before {
      padding-top: perc(640,1200,'%');
    }
  }
  .txtarea {
    @include mq(sp, min, ps) {
      flex: 1;
      margin-top: perc(340,1600,'%');
      margin-left: perc(-324,1600,'%');
      z-index: 1;
      font-size: clamp(rem(10), vw(16), rem(16));
    }
  }
  .txt-attention {
    @include mq(sp, min, ps) {
      font-size: inherit;
    }
  }
  .mce-content-body:not(:last-child) {
    margin-bottom: 1em;
  }
  .mce-content-body .bold {
    background-image: inherit;
  }
}
.home_feature--ttl-sm {
  display: flex;
  @include mq(sp, min, ps) {
    font-size: clamp(rem(18), vw(32), rem(32)) !important;
  }
  @include mq(sp) {
    font-size: sprem(20) !important;
  }
  span {
    display: block;
    &:not(.point) {
      flex: 1;
    }
  }
  .point {
    margin-right: perc(30,16,em);
    font-size: clamp(rem(10), vw(16), rem(16));
    font-weight: 600;
    line-height: 1;
    letter-spacing: .08em;
    padding-top: .5em;
    @include mq(sp) {
      display: none;
    }
  }
  .num {
    font-size: clamp(rem(27), vw(48), rem(48));
    @include mq(sp) {
      font-size: rem(48);
    }
  }
}
.home_feature .btn a {
  @include mq(sp, min, ps) {
    max-width: clamp(180px, vw(320), 320px);
    font-size: clamp(rem(10), vw(18), rem(18));
    svg {
      max-width: clamp(9px, vw(15.9), 15.9px);
    }
  }
}
.home_feature--deco {
  right: perc(730,1680,'%');
  z-index: 1;
  @include mq(sp, min, ps) {
    bottom: clamp(36px, vw(64), 64px);
  }
  @include mq(sp) {
    top: spvw(104);
    right: perc(64,414,'%');
  }
}