@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: max(454px, vw(800));
  @include mq(sp, min, ps) {
    padding-bottom: max(23px, vw(40));
  }
  @include mq(sp) {
    height: spvw(740);
    margin-bottom: spvw(32);
  }
  .inner {
    height: 100%;
    @include mq(sp) {
      position: static;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: spvw(334);
      max-width: spvw(334);
    }
  }
}
.hero_slides {
  display: flex;
  height: 100%;
  margin-left: auto;
  transition: var(--transit-default, all .4s ease-out);
  opacity: 0;
  @include mq(sp, min, ps) {
    width: perc(1080,1680,'%');
  }
  @include mq(sp) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../images/common/ptn-dot.png);
    z-index: -1;
    @include mq(sp) {
      width: spvw(112);
    }
  }
  &::before {
    top: perc(-40,1080,'%');
    left: perc(-40,1080,'%');
    @include mq(sp) {
      top: 0;
      left: spvw(302);
    }
  }
  &::after {
    bottom: perc(-40,1080,'%');
    right: perc(-40,1080,'%');
    @include mq(sp) {
      display: none;
    }
  }
}
.active .hero_slides {
  opacity: 1;
}
.hero_slide {
  display: flex;
  overflow: hidden;
  height: 100%;
  @include mq(sp, min, ps) {
    flex-direction: column;
    flex: 1;
  }
  @include mq(sp) {
    height: spvw(120);
  }
  &:nth-child(even) {
    transform: scaleY(-1);
    li {
      transform: scaleY(-1);
    }
    @include mq(sp) {
      transform: scaleX(-1);
      li {
        transform: scaleX(-1);
      }
    }
  }
}
.hero_slide--list {
  animation: loop_slide 10s infinite linear 1s both;
  @include mq(sp) {
    display: flex;
    animation: loop_slide_sp 10s infinite linear 1s both;
  }
}
.hero_slide--list li {
  border: 2px solid var(--clr-wht, #fff);
  @include mq(sp) {
    width: spvw(180);
  }
  &::before {
    padding-top: perc(360,540,'%');
  }
}
.hero--catch {
  width: 100%;
  @include mq(sp, min, ps) {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  @include mq(sp) {
    position: static;
  }
}
.hero--sankaku {
  top: 10%;
  bottom: 0;
  left: max(-43px, vw(-76));
  margin: auto;
  transform-origin: left;
  width: max(13px, vw(24));
  height: max(49px, vw(87));
  @include mq(sp, min, ps) {
    transform: rotate(-90deg);
  }
  @include mq(sp) {
    left: spvw(-26);
    width: spvw(12);
    height: spvw(44);
  }
}
.hero--sankaku .sankaku {
  display: block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: max(7px, vw(12));
    @include mq(sp) {
      margin-bottom: spvw(7);
    }
  }
  &:nth-child(1) {
    fill: #BDEDE9;
  }
  &:nth-child(2) {
    fill: #91E1DB;
  }
  &:nth-child(3) {
    fill: var(--clr-main, #23C4B7);
  }
}
.hero--ttl {
  margin-bottom: 1em;
  font-size: max(rem(32), vw(56));
  @include mq(sp) {
    font-size: spvw(32);
    margin-bottom: .5em;
  }
}
.hero--txt {
  font-size: max(rem(10), vw(16));
  margin-bottom: perc(56,16,em);
  @include mq(sp) {
    font-size: max(sprem(13), spvw(13));
    margin-bottom: perc(20,13,em);
  }
}
.hero--btn_wrap {
  @include mq(sp, min, ps) {
    width: perc(600,1680,'%');
  }
}
.hero--btn {
  font-size: max(rem(10), vw(16));
  @include mq(sp, min, ps) {
    max-width: max(227px, vw(400));
  }
  @include mq(sp) {
    font-size: spvw(13);
  }
}
.hero--btn .txt-sub {
  display: table;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5em;
  padding-right: 1.5em;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: linear-gradient(to bottom, var(--clr-sub, #333), var(--clr-sub, #333) 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%);
    background-size: 4px 4px;
    width: 2px;
    height: 2em;
  }
  &::before {
    left: 0;
    transform: rotate(-20deg);
  }
  &::after {
    right: 0;
    transform: rotate(20deg);
  }
}
.hero--contact a {
  box-shadow: 0 0 perc(20,30,em) rgba(17,40,97,.2);
  font-size: max(rem(11), vw(20));
  max-width: 100%;
  @include mq(sp) {
    font-size: spvw(16);
  }
  .free {
    font-size: max(rem(10), vw(18));
    @include mq(sp) {
      font-size: spvw(15);
    }
  }
}
.hero--contact svg {
  width: perc(28,20,em);
}
.hero--btn_deco {
  bottom: max(20px, vw(36));
  right: 0;
  width: 100%;
  height: 2px;
  background-color: var(--btn-bg-cta, #333);
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: max(-10px, vw(-18));
    transform: translateY(-50%);
    width: max(18px, vw(32));
    height: max(18px, vw(32));
    border-radius: 50%;
    background-image: radial-gradient(var(--btn-bg-cta, #333) rem(4), rgba($color: #F7B20E, $alpha: .3) rem(4), rgba($color: #F7B20E, $alpha: .3));
  }
}